.order {
  border: 2px solid rgba(119, 119, 119, 0.041);
  padding: 30px;
}

.product {
  display: flex;
  /* 플렉스가 같은 방향일때 공간을 남은공간만큼 똑같이 나눠서 채우는거 */
}

.totalprice {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px;
  padding-right: 0;
  /* 플렉스가 있어야 달수 있음 */
}
.totalpricecontent {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 800px) {
  .totalpricecontent {
    width: 80%;
  }
  .order {
    font-size: 10px;
  }
}
.choiceproduct {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
