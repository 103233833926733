.customcolorpicker-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 90;
}

.sketchpicker {
  position: absolute;
  z-index: 100; /* 포지션을 꼭 줘야 의미가 있음. 숫자가 클수록 위에온다 */
}
