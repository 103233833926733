* {
  /*모든태그에 적용할때 */
  font-family: "Noto Sans KR", sans-serif;
  box-sizing: border-box;
}
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.resetbutton {
  padding: 10px 30px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 20px;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: 0.4s background-color; /* 배경색을 스르륵 바꾸는거 시간 */
}
.resetbutton > img {
  width: 16px;
  height: 16px;
}
.resetbutton:hover {
  background-color: rgb(255, 118, 118);
  cursor: pointer; /* 마우스를 손모양으로 바뀌게하는거 */
}
.saveimagebutton {
  padding: 10px 30px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: rgba(155, 207, 255, 0.8);
}
.buttonarea {
  display: flex;
  justify-content: space-between;
  padding: 10px 60px;
}
.app-wrapper {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.app {
  width: 95%;
}
@media screen and (min-width: 700px) {
  .app-wrapper {
    width: 100%;
  }
  .app {
    width: 700px;
  }
}
@media screen and (max-width: 700px) {
  .buttonarea {
    padding: 10px 20px;
  }
}
