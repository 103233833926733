.colorchip {
  width: 50px;
  height: 50px;
  margin: 2.5px;
  border: 2px solid #dddddd;
  box-sizing: border-box;
  transition: 0.4s filter;
}
.colorchip.active {
  border: 2px solid #000000;
}
.colorchip:hover {
  cursor: pointer;
  filter: brightness(140%);
}
