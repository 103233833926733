.productname {
  width: 20%;
}
.productbuttonlist {
  display: flex;
}
.productbuttonlist > button {
  border: 1px solid white;
}
.productcolorname {
  width: 50%;
}
.productquantity {
  display: flex;
  align-items: center;
}
.productquantitywrapper {
  display: flex;
  justify-content: space-between;
  width: 30%;
}
