.productselector {
  display: flex;
  position: relative;
  padding: 0 10px;
  padding-top: 20px;
  justify-content: space-evenly;
  margin-bottom: 10px;
}
.selector.active {
  border-bottom: 2px solid black;
}
.selector {
  padding: 0 5px;
  padding-bottom: 3px;
}
